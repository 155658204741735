import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useTheme, Theme, Container, Grid, Typography, Box, useMediaQuery } from "@material-ui/core";
import Hero from "../components/hero";
import Section from "../components/section";
import styled from "@emotion/styled";
import SEO from "../components/seo";
import MainLayout from "../layouts/mainLayout";

interface LocationProps {
    location: Location;
}


const VerticalGradientBorder = styled(Box)((props: { theme: Theme }) => `
  background-image: linear-gradient(${props.theme.palette.primary.main}, ${props.theme.palette.secondary.main});
`);

const HorizontalGradientBorder = styled(Box)((props: { theme: Theme }) => `
  background-image: linear-gradient(to right, ${props.theme.palette.primary.main}, ${props.theme.palette.secondary.main});
`);

const StretchGridItem = styled(Grid)`
  align-self: stretch;
`;


const RebuildPage: React.FC<LocationProps> = (props) => {
    const theme = useTheme<Theme>();
    const data = useStaticQuery(graphql`
    query {
        hero: rebuildJson(block: {eq: "hero"}) {
            title
            tagline
            ctaText
            ctaUrl
            cta2Text
            cta2Url
            image {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 4160, maxHeight: 3120) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    }
    `);

    const phone = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <MainLayout transparentNav>
            <SEO title="Rebuilding" location={props.location} />
            <Hero {...data.hero} overlayColor="#FFF" heroHeight={65} backgroundPosition="center center" />

            <Section>
                <Container maxWidth="md">
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h1" align={phone ? "center" : "right"}>Renew</Typography>
                            <HorizontalGradientBorder hidden={!phone} theme={theme} height={5} width="85%" margin="auto"></HorizontalGradientBorder>
                        </Grid>
                        <StretchGridItem item hidden={phone} sm={1}>
                            <Box px={2} height="100%" display="flex" justifyContent="center">
                                <VerticalGradientBorder theme={theme} height="100%" width={6}></VerticalGradientBorder>
                            </Box>
                        </StretchGridItem>
                        <Grid item xs={12} sm={7}>
                            <Box p={2} pb={0}>
                                <Typography variant="h5">
                                    Sometimes, like our brains, our websites too must be restored.
                                    <br /><br />
                                    Please excuse our progress as we bring you a better than ever before TeamSherzai experience.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Section>

        </MainLayout>
    )
}

export default RebuildPage